.blog-header {
    text-align: center;
    padding: 50px 20px;
    background-color: var(--background1Color);
  }
  
  .blog-header h1 {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  
  .blog-header p {
    font-size: 1.2rem;
    color: #666;
  }
  