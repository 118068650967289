@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'); /* Importing Google Font */

/* Common text styles */
.text-title {
  font-family: 'Roboto', sans-serif; /* Apply font family */
  font-size: 2.3rem;
  font-weight: bold;
  
  color: var(--bodyTextColor);
  background: linear-gradient(to bottom, var(--bodyTextColor) 50%, var(--greenColor) 50%);
    padding-top: '20px';
    padding-bottom: '20px';
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.3s ease;
}

.text-paragraph {
  font-family: 'Roboto', sans-serif; /* Apply font family */
  font-size: 1.2rem;
  line-height: 1.6;
  color: var(--bodyTextColor);
  background: linear-gradient(to bottom, var(--bodyTextColor) 50%, var(--greenColor) 50%);

  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.3s ease;
}

/* Hover effect for text */
.text-title:hover, .text-paragraph:hover {
  background-position: bottom;
}

/* Additional styling for other text elements */
.text-subtitle {
  font-family: 'Roboto', sans-serif; /* Apply font family */
  font-size: 1.5rem;
  font-weight: 600;
 
  color: var(--bodyTextColor);
  background: linear-gradient(to bottom, var(--bodyTextColor) 50%, var(--greenColor) 50%);

  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.3s ease;
}

.text-subtitle:hover {
  background-position: bottom;
}

  
/* Mobile-specific styles */
@media (max-width: 768px) {
  .text-title {
    font-size: 1.5rem;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .text-paragraph {
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .text-subtitle {
    font-size: 1.2rem;
  }
}
