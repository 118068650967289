.related-articles {
    padding: 20px;
    background: #fafafa;
    margin-top: 40px;
  }
  
  .related-articles h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .articles-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .article-card {
    width: calc(33% - 20px);
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 20px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .article-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .article-info {
    padding: 15px;
  }
  
  .article-category {
    background: #27ae60;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .article-date {
    color: #888;
  }
  
  .article-title {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  