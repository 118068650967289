/* Colors and Styling */

.experience-about-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px 20px;
  background-color: #fff;
  color: var(--secondary2Color);
  text-align: center;
  /* box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); Shadow added */
  animation: fadeIn 1.5s ease-in-out;
}

.experience-about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
  flex-wrap: wrap;
  border-radius: 20px; /* Rounded corners */
  overflow: hidden; /* Ensure content respects border radius */
  background-color: var(--secondary2Color); /* Slightly different background for contrast */
  padding: 20px;
 /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
}

.experience-about-image {
  flex: 1;
  max-width: 50%;
  overflow: hidden;
  max-height: 700px;
  border-radius: 12px;
  animation: fadeInLeft 1s ease-in-out;
}

.experience-about-image img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  transition: transform 0.3s, filter 0.3s; /* Add transition for visual effects */
}

.experience-about-image img:hover {
  transform: scale(1.05);
  filter: brightness(1.1); /* Slight brightness effect on hover */
}

.experience-about-text {
  flex: 1;
  text-align: left;
  padding: 20px;
  max-width: 50%;
  animation: fadeInRight 1s ease-in-out;
}

/* .experience-about-text h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--titleColor);
  font-weight: 600;
}

.experience-about-text p {
  font-size: 1.2rem;
  color: var(--bodyTextColor);
  margin-bottom: 30px;
  line-height: 1.6;
} */

.aboutstats {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
}

.aboutstat {
  text-align: center;
  flex: 1 1 calc(50% - 20px);
  animation: fadeInUp 1s ease-in-out;
}

.aboutstat h3 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: var(--buttonColor);
  font-weight: 700;
}

.aboutstat p {
  font-size: 1rem;
  color: var(--bodyTextColor);
}

.browse-about-plans-buttons {
  background-color: var(--buttonColor);
  color: var(--secondary2Color);
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  animation: fadeInUp 1s ease-in-out;
}

.browse-about-plans-buttons:hover {
  background-color: var(--buttonDarkColor);
  transform: translateY(-2px);
  color:  var(--buttonColor);

}

@media (max-width: 768px) {
  .experience-about-content {
    flex-direction: column;
  }

  .experience-about-text {
    text-align: left;
    max-width: 100%;
  }

  .experience-about-image {
    max-width: 100%;
  }

  .aboutstat {
    flex: 1 1 100%;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
