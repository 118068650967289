/* src/components/Plans.css */
.plans-section {
  background-color: var(--background1Color);
  padding: 0 30px; 
  text-align: center;
  align-items: center;
  margin-bottom: 40px;
}

.service-plans {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  align-items: center;
  justify-content: center;
}



@media (max-width: 768px) {
.service-plans {
  flex-direction: column;
}
}
