/* .post-card {
    display: flex;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    margin: 20px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .post-card-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  
  .post-card-content {
    padding: 20px;
    flex: 1;
  }
  
  .post-card-category {
    color: #27ae60;
    font-weight: bold;
  }
  
  .post-card-date {
    color: #888;
    margin-left: 10px;
  }
  
  .post-card-title {
    font-size: 1.5rem;
    margin: 10px 0;
  }
  
  .post-card-description {
    color: #666;
  }
   */



   .post-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s;
    margin-bottom: 16px;
  }
  
  .post-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .post-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .post-content {
    padding: 16px;
  }
  
  .post-category {
    display: block;
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
  }
  
  .post-date {
    display: block;
    font-size: 12px;
    color: #999;
    margin-bottom: 8px;
  }
  
  .post-title {
    font-size: 18px;
    margin: 0 0 8px;
    color: #333;
  }
  
  .post-description {
    font-size: 14px;
    color: #555;
  }
  