/* src/components/PlanCard.css */
.plan-link {
    text-decoration: none;
  }
  
  .plan-card {
    background-color: var(--secondary2Color);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px #0000001a;
    width: 300px;
    height: 450px;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s, box-shadow 0.3s;
    margin: 40px auto;
  }
  
  .plan-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
  }
  
  .plan-header {
    height: 200px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    font-size: 1.5em;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  }
  
  .plan-info {
    padding: 20px;
    text-align: left;
    flex: 1; 
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
  }
  
  .plan-info h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: var(--titleColor);
  }
  
  .plan-info p {
    font-size: 1em;
    color: var(--bodyTextColor);
    margin-bottom: 10px;
  }
  
  .plan-info .price {
    font-size: 1.2em;
    color: var(--buttonColor);
    margin-bottom: 10px;
  }
  
  .plan-info ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .plan-info ul li {
    font-size: 0.9em;
    color: var(--bodyTextColor);
    margin-bottom: 5px;
    position: relative;
    padding-left: 20px;
  }
  
  .plan-info ul li:before {
    content: '✔';
    position: absolute;
    left: 0;
    color: var(--buttonColor);
  }
  
  .view-details-btn {
    background-color: var(--buttonColor);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: auto; 
  }
  
  .view-details-btn:hover {
    background-color: var(--buttonDarkColor);
  }
  