/* Updated CSS for Testimonial Component */

/* Desktop and larger devices */
.testimonials {
  padding: 40px;
  background-color: var(--background1Color); /* Light background for contrast */
  text-align: center;
  border-radius: 10px;
  margin: 0 auto;
  position: relative;
}

.testimonial-header {
  margin-bottom: 30px;
}

.testimonial-container {
  position: relative;
  width: 100%;
}

.testimonial-grid {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  padding: 20px 0;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
}

.testimonial-card {
  flex: 1 1 calc(33.333% - 20px); /* Adjust width for multiple cards per row */
  background-color: var(--background2Color); /* White background for cards */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  padding: 20px;
  height: 200px; /* Fixed height for cards */
  box-sizing: border-box;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.pagers {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.scroll-button {
  background-color: var(--buttonColor); /* Button color */
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.5em;
  transition: background-color 0.3s;
  margin: 0 15px; /* Space between buttons and dots */
}

.scroll-button:hover {
  background-color: var(--buttonDarkColor); /* Darker shade on hover */
}

.scroll-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.dots-container {
  display: flex;
  gap: 10px;
  justify-content: center; /* Center dots */
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #bbb; /* Inactive dot color */
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: var(--footerColor); /* Active dot color */
}

/* Media Queries for Responsive Design */

/* Small devices (tablets, 600px and up) */
@media (max-width: 767px) {
  .testimonial-card {
    flex: 1 1 calc(50% - 20px); /* 2 cards per row */
  }

  .testimonial-header h2 {
    font-size: 2em;
  }

  .testimonial-content p {
    font-size: 1em;
  }

  .scroll-button {
    font-size: 1.2em;
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 599px) {
  .testimonial-card {
    flex: 1 1 100%; /* 1 card per row */
  }

  .testimonial-header h2 {
    font-size: 1.6em;
  }

  .testimonial-content p {
    font-size: 0.9em;
  }

  .scroll-button {
    font-size: 1em;
  }
}
