.sidebar {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  
  .sidebar-profile {
    text-align: center;
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .sidebar-socials {
    margin-top: 20px;
  }
  
  .sidebar-socials h4 {
    margin-bottom: 10px;
  }
  
  .social-icons a {
    margin-right: 10px;
    text-decoration: none;
    color: #27ae60;
    font-weight: bold;
  }
  
  .sidebar-plans {
    margin-top: 20px;
  }
  
  .plan-image {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .browse-plans-button {
    background-color: #27ae60;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
  }
  

  






  .author-info {
    text-align: center;
  }
  
  .author-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .social-media-links {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .social-media-link {
    margin: 0 10px;
    color: #000; /* Default icon color */
    transition: color 0.3s ease;
  }
  
  .social-media-link:hover {
    color: #007bff; /* Change color on hover */
  }
  
  .plans {
    /* Your existing styles for plans */
  }
  
  .plan-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  
  .plan-name {
    text-align: center;
  }