.dash-services-container {
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
    background-color: var(--background1Color);
    animation: fadeIn 1s ease-out;
}

.dash-services-cards {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    padding: 20px;
    flex-wrap: wrap; /* Allow wrapping for responsiveness */
}

.dash-service-card {
    background-color: var(--background2Color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: calc(33.333% - 20px); /* Adjust width for multiple cards per row */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    animation: fadeInUp 1s ease-out;
    animation-delay: calc(var(--animation-order) * 0.1s);
    animation-fill-mode: backwards;
    box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.dash-service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.dash-service-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
    transition: transform 0.3s ease;
}

.dash-service-content {
    text-align: left;
    width: 100%;
}


.dash-view-more-btn {
    margin-top: 40px;
    padding: 12px 25px;
    font-size: 16px;
    background-color: var(--buttonColor);
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dash-view-more-btn:hover {
    background-color: var(--buttonDarkColor);
}

/* Keyframe Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Media Queries for Responsive Design */

/* Small devices (tablets, 600px and up) */
@media (max-width: 767px) {
    .dash-service-card {
        width: calc(50% - 20px); /* 2 cards per row */
    }

    .dash-services-heading {
        font-size: 2em;
    }

    .dash-service-description {
        font-size: 14px;
    }

    .dash-view-more-btn {
        font-size: 14px;
    }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 599px) {
    .dash-service-card {
        width: 100%; /* 1 card per row */
    }

    .dash-services-heading {
        font-size: 1.6em;
    }

    .dash-service-description {
        font-size: 13px;
    }

    .dash-view-more-btn {
        font-size: 12px;
        padding: 10px 20px;
    }
}
