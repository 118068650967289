/* Base styles (unchanged) */
.service-description-container {
    padding: 40px 20px;
    margin: 0 auto;
    background-color: var(--background1Color);
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* .service-description-title {
    text-align: center;
    font-size: 2.5em;
    color: var(--titleColor);
    margin-bottom: 40px;
    font-weight: bold;
} */

.service-content {
    display: flex;
    gap: 30px;
}

.service-list {
    flex: 1;
    background-color: var(--background2Color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.service-item {
    margin-bottom: 15px;
    padding: 12px;
    background-color: #fff;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border: 2px solid transparent;
}

.service-item:hover {
    background-color: #e0e0e0;
    transform: translateY(-3px);
    
}

.service-item.active {
    background-color: var(--buttonColor);
    color: white;
    border: 2px solid var(--buttonDarkColor);
}

.service-details {
    flex: 2;
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.service-details img {
    width: 100%; /* Make the image fill the width of the container */
    height: 300px; /* Set a fixed height for the images */
    object-fit: cover; /* Ensure the image covers the area without distortion */
    border-radius: 8px;
    margin-bottom: 20px;
}

/* .service-details-description,
.service-details-more-description {
    font-size: 1.1em;
    line-height: 1.8;
    color: #444;
    margin-bottom: 20px;
} */

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
    .service-content {
        gap: 20px;
    }

    .service-details img {
        height: 250px;
    }

    .service-details-description,
    .service-details-more-description {
        font-size: 1em;
    }
}

@media (max-width: 900px) {
    .service-content {
        flex-direction: column;
    }

    .service-list {
        margin-bottom: 20px;
        padding: 10px;
    }

    .service-details {
        padding: 20px;
    }

    .service-details img {
        height: 200px;
    }

    .service-details-description,
    .service-details-more-description {
        font-size: 0.9em;
    }
}

@media (max-width: 600px) {
    .service-description-title {
        font-size: 1.8em;
    }

    .service-content {
        flex-direction: column;
    }

    .service-list {
        width: 100%;
    }

    .service-details {
        padding: 15px;
    }

    .service-details img {
        height: 150px;
    }

    .service-details-description,
    .service-details-more-description {
        font-size: 0.85em;
    }
}
