/* Colors and Styling */

  .instagram-section {
    max-width: 1200px;
    margin: 40px auto;
    padding: 40px;
    background-color: var(--grayBackgroundColor);
    border-radius: 12px;
    box-shadow: 0 4px 20px var(--sectionShadowColor);
    text-align: center;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .instagram-content {
    background-color: var(--backgroundColor);
    padding: 20px;
  }
/*   
  .instagram-content h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: var(--titleColor);
    font-weight: 600;
  }
  
  .instagram-content p {
    font-size: 1.2rem;
    color: var(--bodyTextColor);
    margin-bottom: 30px;
    line-height: 1.6;
  } */
  
  .instagram-images {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .instagram-images img {
    width: 100%;
    height: auto;
    max-width: 180px;
    border-radius: 12px;
    box-shadow: 0 4px 10px var(--imageShadowColor);
    transition: transform 0.3s ease, filter 0.3s ease;
    animation: fadeInUp 1s ease-in-out;
  }
  
  .instagram-images img:hover {
    transform: scale(1.05);
    filter: brightness(1.1);
  }
  
  .instagram-button {
    background-color: var(--buttonColor);
    color: var(--secondary2Color);
    padding: 15px 30px;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    animation: fadeInUp 1.5s ease-in-out;
  }
  
  .instagram-button:hover {
    background-color: var(--buttonDarkColor);
    transform: translateY(-2px);
    color:  var(--buttonColor);

  }
  
  @media (max-width: 768px) {
    .instagram-images {
      flex-direction: column;
    }
  
    .instagram-images img {
      max-width: 100%;
    }
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  