.join-community-container {
  padding: 50px 0;
  background-color: #f9f9f9;
  text-align: center;
}

.join-community-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.join-community-content img {
  max-width: 100%;
  border-radius: 10px;
  margin-right: 20px;
}

.community-info {
  max-width: 600px;
  text-align: center;
}


.social-links {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.social-link {
  padding: 10px;
  border: 2px solid #333;
  border-radius: 50%;
  color: #333;
  text-decoration: none;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  transition: background-color 0.3s, color 0.3s;
  
}

.social-link:hover {
  background-color: #333;
  color: #fff;
}
