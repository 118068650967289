.newsletter-subscription {
    background-color: var(--background1Color);
    padding: 40px 20px;
    text-align: center;
    border-radius: 12px;
    margin: 40px auto;
    max-width: 800px;
  }
  
  .newsletter-subscription h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .newsletter-subscription input {
    width: 70%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    margin-right: 10px;
  }
  
  .newsletter-subscription button {
    padding: 15px 30px;
    background-color: var(--buttonColor);
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .newsletter-subscription button:hover {
    background-color: var(--buttonDarkColor);
  }
  