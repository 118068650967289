
  .first-step-container {
    background-color: var(--backgroundColor);
    /* padding: 50px 0; */
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    width: 100vw; /* Full viewport width */

    justify-content: center;
  }
  
  .first-step-content {
    text-align: center;
    padding: 20px;
    background: var(--background1Color);
    border-radius: 12px;
    /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */
    animation: fadeIn 1.5s ease-in-out;
  }
  
 
  .first-step-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  
  .first-step-buttons button {
    padding: 15px 30px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  }
  
  .browse-plans {
    background-color: var(--buttonColor);
    color: var(--secondary2Color);
    border-radius: 8px;
    color:  var(--secondary2Color);

  }
  
  .browse-plans:hover {
    background-color: var(--buttonDarkColor);
    transform: translateY(-2px);
    color:  var(--greenColor);

  }
  
  .contact-me {
    background-color: var(--secondary2Color);
    color: var(--buttonColor);
    border: 2px solid var(--buttonColor);
    border-radius: 8px;
    
  }
  
  .contact-me:hover {
    background-color: var(--buttonColor);
    color:  var(--secondary2Color);
    border-color: var(--buttonHoverBorderColor);
    transform: translateY(-2px);
  }
  
  /* Optional: Add some wave-like background decoration */
  .first-step-container::before,
  .first-step-container::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 30px;
   /*background: url('./wave-pattern.svg') repeat-x; /* Replace with your wave pattern image */
  }
  
  .first-step-container::before {
    top: -30px;
    left: 0;
  }
  
  .first-step-container::after {
    bottom: -30px;
    left: 0;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  