.cta {
  background-color: var(--background1Color);
  padding: 60px;
  border-radius: 12px;
  text-align: left;
  font-family: 'Arial', sans-serif;
  /* Ensure it doesn't exceed a reasonable width */
}

.cta-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cta-text {
  flex: 1;
  margin-right: 20px;
  max-width: 600px;
}


.cta button {
  background-color: var(--buttonColor);
  color: var(--secondary2Color);
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.cta button:hover {
  background-color: var(--buttonDarkColor);
}

.cta-image {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.cta-image img {
  max-width: 80%;
  height: auto;
  border-radius: 12px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .cta {
    padding: 30px;
    width: calc(100% - 20px); /* Adjust for smaller screen spacing */
  }

  .cta-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .cta-text {
    margin-right: 0;
    margin-bottom: 20px;
    max-width: 100%; /* Ensure full width on smaller screens */
  }

  .cta-image {
    order: -1; /* Move the image to the top */
    padding-right: 0;
    margin-top: 20px; /* Space between text and image */
  }

  .cta-image img {
    max-width: 80%; /* Ensure image fits well on smaller screens */
  }
}
