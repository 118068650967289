/* Base styles (unchanged) */
.plan-single-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  margin-top: 40px;
  height: 100vh;
  font-family: 'Arial', sans-serif;
  background-color: var(--background1Color);
  animation: fadeIn 1.5s ease-in-out;
  gap: 2rem;
}

.plan-infos {
  flex: 2;
  padding: 2rem;
  background-color: var(--background2Color);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.plan-features {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
  animation: fadeInUp 1.4s ease-in-out;
}

.plan-features li {
  margin-bottom: 0.5rem;
}

.about-plan {
  margin-top: 2rem;
}


.plan-card {
  flex: 1;
  text-align: center;
  background-color: var(--background2Color);
  padding: 1rem; /* Fixed padding */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  /* animation: fadeInRight 2s ease-in-out; */
}

.plan-card:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}

.plan-image {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.plan-price {
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.plan-duration {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
}

.plan-duration label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: var(--labelColor); /* Define the color for labels */
}

.plan-duration select {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid var(--borderColor); /* Define border color for select */
}

.total-price {
  font-size: 1.25rem;
  margin-top: 0.5rem;
  color: var(--priceColor); /* Define color for total price */
}

.add-to-cart {
  background-color: var(--buttonColor);
  color: var(--secondary2Color);
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  /* animation: fadeInRight 2.8s ease-in-out; */
}

.add-to-cart:hover {
  background-color: var(--buttonDarkColor);
}

.not-found {
  text-align: center;
  font-size: 1.5rem;
  color: red;
  margin-top: 2rem;
  animation: fadeIn 1s ease-in-out;
}

/* Keyframe Animations (unchanged) */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .plan-single-container {
    gap: 1.5rem;
    padding: 1.5rem;
  }

  .plan-info {
    padding: 1.5rem;
  }

  .plan-card {
    padding: 1rem;
  }
}

@media (max-width: 900px) {
  .plan-single-container {
    flex-direction: column;
    height: auto; /* Adjust height for smaller screens */
  }

  .plan-info {
    width: 100%;
    margin-bottom: 2rem;
  }

  .plan-card {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .plan-title {
    font-size: 1.8rem;
  }

  .plan-description {
    font-size: 1rem;
  }

  .plan-features li {
    font-size: 0.9rem;
  }

  .about-plan h2 {
    font-size: 1.5rem;
  }

  .about-plan p {
    font-size: 0.9rem;
  }

  .plan-price {
    font-size: 1.2rem;
  }

  .plan-duration label,
  .plan-duration select,
  .total-price {
    font-size: 0.9rem;
  }

  .add-to-cart {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}
