

/* Sidebar content styling */
.sidebar-content {
  background-color: #ffffff; /* White background for the sidebar */
  height: 100%; /* Full height of the container */
  overflow-y: auto;
  position: sticky; /* Make the sidebar sticky */
  top: 20px; /* Offset from the top */
  transition: top 0.3s ease; /* Smooth transition for the sticky effect */
}

/* Style adjustments for sticky sidebar */
.sidebar-content.sticky {
  position: fixed; /* Fixed position when sticky */
  top: 20px; /* Offset from the top */
  right: 40px; /* Offset from the right edge */
  height: calc(100vh - 20px); /* Full viewport height minus offset */
  max-height: calc(100vh - 20px); /* Ensure it does not exceed viewport height */
}




/* Overall section styling */
.latest-posts-section {
  display: flex;
  flex-direction: column;
  padding: 20px;
}


/* Container for posts and sidebar */
.latest-posts-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensure children elements stretch equally */
  flex-grow: 1; /* Allow container to grow */
}

/* .latest-posts-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; 
  flex-grow: 1;
} */


/* List of posts */
.posts-list {
  flex: 3;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}





/* Sidebar container */
.sidebar-container {
  /* flex: 1; */
  background-color: #f9f9f9; /* Light background for better contrast */
  border-left: 1px solid #ddd;
  position: relative;
  width: 300px;
}


/* 
.sidebar-container {
  flex: 1; 
  background-color: #ba8282;
  border-left: 1px solid #ddd;
  overflow: hidden; 
  position: relative; 
  display: flex;
  flex-direction: column;
  width: 300px; 
} */




/* Sidebar content styling */
.sidebar-content {
  background-color: var(--background1Color);
  height: 100vh; /* Full height of the container */
  overflow-y: auto;
  position: sticky; /* Make the sidebar sticky */
  top: 20px; /* Offset from the top */
  transition: padding-top 0.3s ease; 
}


/* 

.sidebar-content {
  width: 300px; 
  background-color: #703333;
  height: 100vh;
  overflow-y: auto;
  align-self: flex-start;
  transition: padding-top 0.3s ease; 
} */

.sidebar-content.sticky {
  width: 300px; /* Fixed width when sticky */
  top: 80px;
  right: 20px;
  height: 100vh;
  position: fixed;
  /* Ensure that it stays within the viewport's height */
  max-height: calc(100vh - 80px);
}

.sidebar-content.scroll-with-posts {
  position: absolute; /* Positioned relative to sidebar-container */
  width: 300px; /* Default width */
  top: 0; /* Ensure it starts from the top */
}



/* Placeholder styles for responsive adjustments */
.placeholder {
  width: 300px;
  display: none; /* Hide by default */
}

.placeholder.show {
  width: 300px;
  display: block; /* Show when the sidebar is sticky */
}





/* Responsive design adjustments */
@media (max-width: 768px) {
  .latest-posts-section {
    flex-direction: column;
  }

  .latest-posts-content {
    flex-direction: column;
  }

  .posts-list {
    margin-right: 0;
    padding-top: 20px; /* Add spacing above the posts list */
  }

  .sidebar-container {
    width: 100%; /* Full width on smaller screens */
    margin-top: 20px; /* Add margin above the sidebar */
  }

  .sidebar-content {
    width: 100%; /* Full width on smaller screens */
    height: auto; /* Auto height on smaller screens */
    position: static; /* Remove fixed position on smaller screens */
    top: auto; /* Remove top offset */
  }
}



