/* Base styles (unchanged) */
.services-container {
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
    background-color: var(--background1Color);
    animation: fadeIn 1s ease-out;
}

.services-heading {
    font-size: 2.5em;
    color: var(--titleColor);
    margin-bottom: 40px;
    margin-top: 40px;
    animation: fadeInDown 1s ease-out;
}

.services-row {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin-bottom: 40px;
}

.service-card {
    background-color: var(--background2Color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 30%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
    transition: transform 0.3s ease;
}



.services-description {
    font-size: 1.2em;
    color: var(--textColor);
    margin-bottom: 40px;
    line-height: 1.6;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.read-more-btn {
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 14px;
    background-color: var(--buttonColor);
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.read-more-btn:hover {
    background-color: var(--buttonDarkColor);
    color:  var(--buttonColor);

}

/* Keyframe Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
    .services-row {
        justify-content: space-between;
    }
    
    .service-card {
        width: 45%;
    }
}

@media (max-width: 900px) {
    .services-heading {
        font-size: 2em;
    }
    
    .services-description {
        font-size: 1em;
    }
    
    .services-row {
        flex-direction: column;
        align-items: center;
    }
    
    .service-card {
        width: 80%;
        margin-bottom: 20px;
    }
}

@media (max-width: 600px) {
    .services-heading {
        font-size: 1.5em;
    }
    
    .services-description {
        font-size: 0.9em;
    }
    
    .service-card {
        width: 100%;
    }
    
    .service-image {
        height: 150px;
    }
    
    .read-more-btn {
        padding: 8px 16px;
        font-size: 12px;
    }
}
