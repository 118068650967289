.topbar {
    background-color: var(--lightPink);
    padding: 10px 20px;
    text-align: right;
    font-size: 14px;
    color: var(--textColor);
    border-bottom: 1px solid #e5e5e5;
}

.contact-info {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.contact-info .email,
.contact-info .phone {
    color: #007bff;
    text-decoration: none;
}

.contact-info .email:hover,
.contact-info .phone:hover {
    text-decoration: underline;
}
