/* ValuesSection.css */

/* Base Styles */
.values-section {
  padding: 40px;
  background-color: var(--grayBackgroundColor);
  text-align: center;
  animation: fadeIn 1.5s ease-in-out;
  color: var(--secondary2Color);
}

.values-header {
  margin-bottom: 40px;
}

.values-header h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: var(--bodyTextColor);
  animation: slideInFromTop 1s ease-in-out;
}

.values-header p {
  font-size: 1.2rem;
  color: var(--bodyTextColor);
  margin-bottom: 20px;
  animation: slideInFromTop 1s ease-in-out;
}

.values-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.value-card {
  background-color: var(--background2Color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33.333% - 40px);
  max-width: calc(33.333% - 40px);
  min-width: 280px;
  text-align: left;
  color: var(--secondary1Color);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
  animation: fadeInUp 1s ease-out;
  animation-delay: calc(var(--animation-order) * 0.1s);
  animation-fill-mode: backwards;
}

.value-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.value-card .icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--lightGreenColor);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
  margin-bottom: 15px;
  position: relative;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  overflow: hidden;
}

.value-card .icon::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 50%;
  background: linear-gradient(to top, var(--buttonColor), transparent);
  border-radius: 50% 50% 0 0;
  transition: height 0.5s ease-in-out;
}

.value-card .icon:hover {
  transform: scale(1.1);
}

.value-card .icon:hover::before {
  height: 120%;
}

/* Keyframe Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .value-card {
    flex: 1 1 calc(50% - 40px); /* Two cards per row */
    max-width: calc(50% - 40px);
  }
}

@media (max-width: 768px) {
  .value-card {
    flex: 1 1 100%; /* Full width cards on small screens */
    max-width: 100%;
  }
  
  .values-header h2 {
    font-size: 2rem; /* Smaller font size for headers */
  }

  .values-header p {
    font-size: 1rem; /* Adjust font size for paragraphs */
  }

  .value-card .icon {
    font-size: 2rem; /* Smaller icon size */
  }

  .value-card h3 {
    font-size: 1.5rem; /* Smaller title font size */
  }

  .value-card p {
    font-size: 1rem; /* Adjust text size */
  }
}

@media (max-width: 480px) {
  .values-header h2 {
    font-size: 1.5rem; /* Further reduce header font size */
  }

  .values-header p {
    font-size: 1.2rem; /* Further adjust paragraph font size */
  }

  .value-card .icon {
    font-size: 1.5rem; /* Further reduce icon size */
  }

  .value-card h3 {
    font-size: 1.2rem; /* Further reduce title font size */
  }

  .value-card p {
    font-size: 1.20rem; /* Further adjust text size */
  }
}
