.blog-search {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .blog-search input {
    width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  