/* Base Styles */
.payment-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top:20px;
  margin: 0;
  padding: 20px;
  background-color: #f8f9fa; 
  color: #212529; 
  font-family: 'Roboto', sans-serif;
  position: relative;
}

.left-section, .right-section {
  padding: 20px;
  margin-top: 60px;
  box-sizing: border-box;
}

.left-section {
  background-color: var(--secondary2Color);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;

  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.right-section {
  background-color: var(--background2Color);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.image-container {
  /* margin-bottom: 10px;  */
  display: flex;
  justify-content: center;
}

.top-image {
  width: 300px; /* Adjust the size of the image */
  height: auto;
}

.instructions {
  font-size: 1em;
  line-height: 1.6;
  color: #495057; 
}

.plan-title {
  font-size: 1.75em;
  font-weight: 700;
  margin-bottom: 15px;
  color: #212529;
}

/* .plan-features {
  list-style-type: disc; 
  margin: 0;
  padding-left: 20px;
  color: #495057;
} */

/* .plan-features li {
  margin-bottom: 10px;
} */

/* .duration, .plan-price {
  font-size: 1.1em;
  font-weight: 500;
  margin-top: 10px;
  color: #212529;
} */

.qr-code {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.qr-code svg {
  width: 220px;
  height: 220px;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.qr-code svg:hover {
  transform: scale(1.05);
}

/* Responsive Styles */
@media (min-width: 768px) {
  .payment-container {
    flex-direction: row;
    padding: 40px;
  }

  .left-section, .right-section {
    padding: 30px;
    flex: 1;
  }

  .left-section {
    margin-right: 20px;
  }

  .right-section {
    margin-left: 20px;
    justify-content: flex-start;
  }
}

@media (max-width: 767px) {
  .payment-container {
    flex-direction: column;
  }

  .left-section, .right-section {
    padding: 15px;
  }

  .plan-title {
    font-size: 1.5em;
  }

  .qr-code svg {
    width: 180px;
    height: 180px;
  }
}

@media (max-width: 480px) {
  .plan-title {
    font-size: 1.3em;
  }

  .qr-code svg {
    width: 150px;
    height: 150px;
  }
}
