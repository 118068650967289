.contact-us {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  background-color: var(--background1Color);
  border-radius: 10px;
  flex-wrap: wrap; /* Allow flex items to wrap on smaller screens */
}

.contact-us-left {
  flex: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* background: radial-gradient(circle, var(--buttonColor) 100%, var(--buttonColor) 100%); */
    padding: 40px;
    background-color: var(--secondary2Color);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center; 
  align-items: center; 

}


.contact-item {
  display: flex;
  flex-direction: column;
  padding-top: 30px ;
  padding-bottom: 60px;
}


.hero-about {
  background-color: var(--background2Color);
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
}


/* Contact Information styles */
.hero-contact-info {
  text-align: center;
 /* background-color: rgba(0, 0, 0, 0.5); /* Overlay to improve readability */
  background-color: var(--footerColor);
  padding: 60px;
  border-radius: 10px;
  width: 100%; /* Ensures the content stays centered and responsive */
}

.contact-us-form {
  flex: 1;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-us-form form {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}



.contact-us-form .form-group {
  margin-bottom: 15px;
}

.contact-us-form .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.contact-us-form .form-group input,
.contact-us-form .form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  color: #333;
}

.contact-us-form .form-group textarea {
  resize: vertical;
}

.contact-us-form button {
  background-color: var(--buttonColor);
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.contact-us-form button:hover {
  background-color: var(--buttonDarkColor);
}


/* Responsive Styles */
@media (max-width: 768px) {
  .contact-us {
    padding: 20px;
    flex-direction: column; /* Stack sections vertically */
  }

  .contact-us-left,
  .contact-us-form {
    padding: 0;
  }

  .contact-us-form form {
    padding: 15px;
  }

  .hero-about {
    padding: 20px;
  }

  .hero-contact-info {
    font-size: 14px;
  }

  .contact-us-form button {
    padding: 12px 20px;
  }
}
