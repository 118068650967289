.feature-section {
    padding: 40px;
    background-color: var(--background1Color);
    text-align: center;
    animation: fadeIn 1s ease-out;
}


.feature-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.feature-item {
    background-color: var(--background2Color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    flex: 1 1 calc(33.333% - 40px);
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    animation: fadeInUp 1s ease-out;
    animation-delay: calc(var(--animation-order) * 0.1s);
    animation-fill-mode: backwards;
}

.feature-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.feature-item .icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: var(--lightGreenColor);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    margin-bottom: 15px;
    position: relative;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    overflow: hidden;
}

.feature-item .icon::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, var(--buttonColor), transparent);
    border-radius: 50% 50% 0 0;
    transition: height 0.5s ease-in-out;
}

.feature-item .icon:hover {
    transform: scale(1.1);
}

.feature-item .icon:hover::before {
    height: 120%;
}


/* Keyframe Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
