.icon-bar {
  position: fixed;
  bottom: 5px; /* Distance from the bottom of the screen */
  right: 5px;  /* Distance from the right side of the screen */
  display: flex;
  flex-direction: column; /* Aligns the icons vertically */
  gap: 15px; /* Space between the icons */
  z-index: 1000; /* Ensures the icon bar is on top of other elements */
}

.icon-bar a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #25d366; /* WhatsApp color */
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Added transform transition */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Box shadow for depth */
}

.icon-bar a.phone {
  background-color: #007bff; /* Phone icon color */
}

.icon-bar a:hover {
  background-color: #128c7e; /* WhatsApp hover color */
  transform: scale(1.1); /* Scale effect on hover */
}

.icon-bar a.phone:hover {
  background-color: #0056b3; /* Phone hover color */
  transform: scale(1.1); /* Scale effect on hover */
}

/* Accessibility focus styles */
.icon-bar a:focus {
  outline: 2px solid #fff; /* White outline for focus */
  outline-offset: 2px; /* Offset for visibility */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .icon-bar {
    gap: 10px; /* Reduced gap for smaller screens */
  }
  
  .icon-bar a {
    width: 40px;
    height: 40px; /* Smaller icons on mobile */
  }
}