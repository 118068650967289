.heros {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end; /* Align content to the right */
  height: 600px; /* Adjust height as needed */
  background-color: var(--background1Color);
  background-size: cover; /* Adjust background size as needed */
  background-position: center; /* Adjust background position as needed */
  padding: 20px;
}

.heros-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: 50%; /* Adjust width as needed, e.g., 50% for half width */
  max-width: 600px; /* Optional: set a max width for better control */
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  border-radius: 10px;
  box-sizing: border-box; /* Ensure padding is included in width */
  text-align: center; /* Center-align text */
}


.heros p {
  font-size: 1.2em;
  line-height: 1.5;
  margin-bottom: 30px;
}

.heros button {
  background-color: var(--buttonColor);
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
}

.heros button:hover {
  background-color: var(--buttonDarkColor);
  color:  var(--buttonColor);
}
/* Mobile Support */
@media (max-width: 768px) {
  .heros {
    flex-direction: column; /* Stack content vertically on mobile */
    height: auto; /* Adjust height for mobile screens */
    padding: 20px;
  }

  .heros-content {
    width: 100%; /* Full width on mobile */
    max-width: none; /* Remove max-width constraint */
    padding: 15px; /* Adjust padding for mobile */
    margin-top: 30px;
    background-color: rgba(255, 255, 255, 0.9); /* Slightly more opaque on mobile */
  }

  .heros-content h1 {
    font-size: 28px; /* Adjust font size for mobile */
  }

  .heros-content h2 {
    font-size: 22px; /* Adjust font size for mobile */
  }

  .heros p {
    font-size: 1.30em; /* Adjust font size for mobile */
    margin-bottom: 20px; /* Adjust margin for mobile */
  }:hover

  .heros button {
    padding: 10px 20px; /* Adjust padding for mobile */
    font-size: 1em; /* Adjust font size for mobile */
  }

}


.browse-hero-plans {
  background-color: var(--buttonColor);
  color: var(--secondary2Color);
  border-radius: 10px; 
}

.browse-hero-plans:hover {
  background-color: var(--buttonDarkColor);
  transform: translateY(-2px);
  color: #fff;
}

.contact-hero-me {
  background-color: var(--buttonDarkColor);
  color: var(--footerColor);
  border: 2px solid var(--buttonColor);
  border-radius: 10px; 
}

.contact-hero-me:hover {
  background-color: var(--buttonColor);
  color: var(--footerColor);
  transform: translateY(-2px);
}


