/* General App Styling */
.App {
  position: relative;
  z-index: 1;
}

.App-header {
  background: var(--background1Color);
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  /* margin: 20px; */
  overflow: hidden;
}

/* Hero Section Styling */
.hero {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  background: var(--heroBackgroundColor);
  border-radius: 10px;
  overflow: hidden;
}

.hero-image {
  width: 50%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.hero-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 10px;
  box-sizing: border-box;
}

.hero-content button {
  padding: 12px 24px;
  font-size: 1.1rem;
  background-color: var(--buttonColor);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero-content button:hover {
  background-color: var(--buttonDarkColor);
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
  }

  .hero-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .hero-content {
    width: 100%;
    padding: 20px;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content h2 {
    font-size: 1.5rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .hero-content button {
    padding: 10px 20px;
    font-size: 1rem;
  }
}
