.faq-container {
    padding: 50px 0;
    background-color: var(--background1Color);
    text-align: center;
  }
  
  .accordion {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .accordion-item {
    background-color:  var(--background2Color);
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  
  .accordion-title {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #f1f1f1;
  }
  
 
  .accordion-title span {
    font-size: 1.5em;
  }
  
  /* .accordion-content {
    padding: 20px;
    border-top: 1px solid #ddd;
    background-color: #fff;
    text-align: left;
    color: var(--bodyTextColor);
  }
   */