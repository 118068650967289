.blog-post-header {
    text-align: center;
    padding: 40px 20px;
    background: #fafafa;
  }
  
  .header-info {
    margin-bottom: 20px;
  }
  
  .category {
    background: #27ae60;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .date {
    color: #888;
  }
  
  .title {
    font-size: 2.5rem;
    margin: 20px 0;
  }
  
  .description {
    color: #666;
  }
  
  .header-image {
    width: 100%;
    max-width: 800px;
    border-radius: 8px;
    margin: 20px auto;
  }
  