.logo {
    display: flex;
    align-items: center;
    transition: opacity 0.3s;
  }
  
  .logo img {
    height: 100px; /* Initial height */
    margin-right: 10px;
    transition: transform 0.3s, height 0.3s;
  }
  
  .logo-text {
    transition: opacity 0.3s;
    color: #fff; /* Adjust the color as needed */
  }
  
  .logo:hover img {
    transform: scale(0.8); /* Decrease size on hover */
  /*  height: 50px; /* Adjust height on hover */
  }

  logo:hover .logo-text {
   /* transform: translateX(-10px); /* Move text on hover */
    transform: scale(0.8); /* Decrease size on hover */

  }
  
  .logo:hover {
    opacity: 0.8;
  }
  