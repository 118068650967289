.approach-container {
  padding: 20px;
  background-color: var(--background1Color);
  border-radius: 8px;
  width: 100%;

  text-align: center;
  margin: 0 auto;
}

.approach-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 20px;
  justify-content: center;
}
