.contact-form-container {
  padding: 50px 20px;
  background-color: var(--background1Color);
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
  font-family: 'Arial, sans-serif';
}

.contact-form-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.contact-form-left, .contact-form-right {
  flex: 1;
  min-width: 300px;
  padding: 0; }

.contact-form-left {
  background-color: var(--background2Color);
  padding: 40px;
}


.contact-form-left form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form-left input, .contact-form-left textarea {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

.contact-form-left button {
  padding: 15px;
  border: none;
  background-color: var(--buttonColor);
  color: #fff;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.contact-form-left button:hover {
  background-color: var(--buttonDarkColor);
}

.contact-form-right {
  position: relative;
  overflow: hidden; /* Ensures image doesn't overflow the container */
}

.contact-form-right .location-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
  cursor: pointer;
}

.glass-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
