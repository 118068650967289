
.hover-card-button {
  background-color: var(--buttonColor); /* Button background color */
  color: white; /* Initial text color */
  padding: 15px 30px; /* Button padding */
  border: none; /* Remove border */
  font: 1rem;
  font-weight: 600;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  position: relative; /* For pseudo-element positioning */
  overflow: hidden; /* Hide overflow */
  transition: transform 0.3s ease; /* Smooth transition */
}

.hover-card-button::before {
  content: ''; /* No content, just the animation effect */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--darkPinkColor); /* Hover text color */
  z-index: 1;
  transform: translateY(-100%);
  transition: transform 0.3s ease; /* Animation duration and easing */
}

.hover-card-button:hover::before {
  transform: translateY(0); /* Move down to cover the text */
}

.hover-card-button span {
  position: relative;
  z-index: 2; /* Ensure text is above the pseudo-element */
  display: block;
  transition: color 0.3s ease; /* Ensure text color transitions smoothly */
}

.hover-card-button:hover span {
  color: white; /* Final color after hover */
}



/* Mobile-specific styles */
@media (max-width: 768px) {
  .hover-card-button {
    padding: 10px 20px; /* Reduced padding for smaller screens */
    font-size: 0.8rem; /* Slightly smaller font size */
    border-radius: 4px; /* Slightly smaller border radius */
  }

  .hover-card-button::before {
    transition: transform 0.25s ease; /* Slightly faster transition for mobile */
  }

  .hover-card-button span {
    font-size: 0.8rem; /* Match the button font size */
  }
}