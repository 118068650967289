/* Desktop styles */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--secondary2Color);
  color: var(--titleColor);
  padding: 1rem 2rem;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin-top: 0;
}

.navbar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 2rem;
  align-items: center; /* Align items vertically center */
}

.navbar-menu li {
  margin: 0;
}

.navbar-menu li a {
  color: var(--titleColor);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s, border-bottom 0.3s;
}

.navbar-menu li a:hover,
.navbar-menu li a.active {
  color: var(--buttonDarkColor);
  border-bottom: 2px solid var(--buttonDarkColor);
}

/* Mobile styles */
@media (max-width: 768px) {
  .navbar {
    padding: 0.75rem 1.5rem;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    margin-left: auto;
    padding: 0.5rem;
  }

  .hamburger div {
    width: 30px;
    height: 3px;
    background-color: var(--titleColor);
    margin: 5px 0;
    border-radius: 2px;
    transition: transform 0.3s ease;
  }

  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    gap: 0rem;
    background-color: var(--secondary2Color);
    padding: 1rem 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    z-index: 999;
    transform: translateY(-20px);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .navbar-menu.open {
    display: flex;
    transform: translateY(0);
    opacity: 1;
  }

  .navbar-menu li {
    margin: 0;
    text-align: center; /* Center align text in mobile view */
  }

  .navbar-menu li a {
    display: block;
    padding: 1rem;
  }
}



/* Mobile styles */
@media (max-width: 768px) {
  .navbar {
    padding: 0.25rem 0.75rem;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    margin-left: auto;
    padding: 0.5rem;
  }

  .hamburger div {
    width: 30px;
    height: 3px;
    background-color: var(--titleColor);
    margin: 5px 0;
    border-radius: 2px;
    transition: transform 0.3s ease;
  }

  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--secondary2Color);
    padding: 0.5rem 0; /* Reduced padding */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    z-index: 999;
    transform: translateY(-20px);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .navbar-menu.open {
    display: flex;
    transform: translateY(0);
    opacity: 1;
  }

  .navbar-menu li {
    margin: 0;
    text-align: center; /* Center align text in mobile view */
  }

  .navbar-menu li a {
    display: block;
    padding: 0.75rem; /* Reduced padding */
    font-size: 0.9rem; /* Smaller font size */
  }
}
