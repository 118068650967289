/* .hover-button {
  background-color: var(--buttonColor); 
  color: white; 
  padding: 15px 30px; 
  border: none; 
  font: 1rem;
  font-weight: 600;
  border-radius: 8px; 
  cursor: pointer; 
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.hover-button:hover {
  background-color: var(--buttonColor);
   transform: translateY(-2px);  
  color: var(--greenColor); 
} */



.hover-button {
  background-color: var(--buttonColor); /* Button background color */
  color: white; /* Initial text color */
  padding: 15px 30px; /* Button padding */
  border: none; /* Remove border */
  font: 1rem;
  font-weight: 600;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  position: relative;
  overflow: hidden; /* Hide overflow */
  transition: transform 0.3s ease; /* Smooth transition */
}

.hover-button span {
  position: relative;
  display: inline-block;
  background: linear-gradient(to bottom, white 50%, var(--greenColor) 50%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.3s ease;
}

.hover-button:hover span {
  background-position: bottom; /* Shift gradient down on hover */
}



/* Mobile-specific styles */
@media (max-width: 768px) {
  .hover-button {
    padding: 10px 20px; /* Reduced padding for smaller screens */
    font-size: 0.8rem; /* Slightly smaller font size */
    border-radius: 4px; /* Slightly smaller border radius */
  }

  .hover-button span {
    font-size: 0.8rem; /* Match the button font size */
  }
}