@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.App {
  text-align: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Colors and Styling */

/* :root {
  --background1Color: #F6FBE9;
  --background2Color: #eefae5;
  --buttonColor: #83ce67;
  --buttonDarkColor: #ebbb11;
  --titleColor: #01120C;
  --bodyTextColor: #1A3129;
  --secondary1Color: #01120C;
  --grayBackgroundColor: #f9f9f9;
  --secondary2Color: #FFF;
  --borderColor: #DDD;
  --navbarHeight: 90px;
  --background1Color: #F6FBE9;
} */

:root {
--greenColor:#0b2502;
--lightGreenColor: #a8c256;
--pinkColor: #e53f66;
--lightPink: #fbebe6;
--darkPinkColor: #BE1A40;


  --background1Color: #fff;
  --background2Color: #fbebe6;
  --buttonColor: #e53f66;
  --footerColor: #0b2502;
  --buttonDarkColor: #e53f66;
  --titleColor: #01120C;
  --bodyTextColor: #1A3129;
  --secondary1Color: #01120C;
  --grayBackgroundColor: #f9f9f9;
  --secondary2Color: #FFF;
  --borderColor: #DDD;
  --navbarHeight: 90px;
  --background1Color: #fff;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

html, body {
  height: 100%;
  width: 100%;
}


main {
  padding-top: 15px; /* Adjust this value based on the height of your navbar */
  width: 100%;
  overflow-x: initial; /* Prevent horizontal scrolling */
}

footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1rem;
  /* position: absolute; */
  width: 100%;
  bottom: 0;
}

div {
  /* padding: 2rem; */
  width: 100%;
}
