.footer {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--footerColor); /* You might want to update this to a darker color if needed */
  padding: 40px 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.about-section,
.links-section,
.help-section,
.contact-section {
  flex: 1;
  margin: 10px;
  color: var(--secondary2Color); /* Set all text color to white */
}

.logo {
  width: 300px;
}

.about-section p,
.links-section ul,
.help-section ul {
  font-size: 16px;
  line-height: 1.6;
  color: var(--secondary2Color); /* Set all paragraph text color to white */
}

.links-section ul,
.help-section ul {
  list-style: none;
  padding: 0;
}

.links-section ul li,
.help-section ul li {
  margin-bottom: 8px;
}

.links-section ul li a,
.help-section ul li a {
  color: var(--secondary2Color); /* Set link color to white */
  text-decoration: none;
}

.footer-bottom {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #FFF; /* Set border color to white */
  margin-top: 20px;
}

.footer-bottom p {
  color: var(--secondary2Color); /* Set bottom text color to white */
}

/* Apply the font styling to footer text with white color */
.footer h4 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--secondary2Color); /* Set heading color to white */
  background: linear-gradient(to bottom, var(--secondary2Color)  50%, var(--secondary2Color) 50%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.3s ease;
}

.footer h4:hover {
  background-position: bottom;
}

.footer p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  line-height: 1.6;
  color:  var(--secondary2Color); /* Set paragraph text color to white */
  background: linear-gradient(to bottom, var(--secondary2Color)  50%, var(--secondary2Color) 50%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.3s ease;
}

.footer p:hover {
  background-position: bottom;
}

/* Ensure that links in the footer have consistent styling */
.footer a {
  font-family: 'Roboto', sans-serif;
  color:  var(--secondary2Color); /* Set link color to white */
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}


/* Adjust layout for mobile devices */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Stack sections vertically */
  }

  .about-section,
  .links-section,
  .help-section,
  .contact-section {
    margin: 10px 0; /* Adjust margins for stacked layout */
  }

  .contact-section {
    order: 4; /* Move contact section to the bottom */
  }

  .footer h4 {
    font-size: 1.3rem; /* Adjust heading size for mobile */
  }

  .footer p {
    font-size: 1rem; /* Adjust paragraph size for mobile */
  }

  .footer a {
    font-size: 1rem; /* Adjust link size for mobile */
  }
}