/* NutritionPlans.css */
.nutrition-plans-container {
  text-align: center;
  padding: 20px;
  padding-top: 40px;
  margin-top: 40px;
  background-color: var(--background1Color);
  font-family: Arial, sans-serif;
  animation: fadeIn 1.5s ease-in-out;
}



.intro {
  max-width: 1200px;
  margin: 0 auto;
  color: var(--bodyTextColor);
  font-size: 18px;
  margin-bottom: 20px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@keyframes fadeInDown {
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

